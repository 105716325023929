import { LoadingButton, useModalState } from "@clipboard-health/ui-react";
import { isDefined } from "@clipboard-health/util-ts";
import { Button } from "@mui/material";
import { useGetMissingRequirementsForDate } from "@src/appV2/Accounts/Documents/api/useGetMissingRequirementsForDate";
import { getQualificationForDocumentCheck } from "@src/appV2/Accounts/Documents/helpers";
import { ACCOUNTS_DOCUMENTS_FULL_PATH } from "@src/appV2/Accounts/Documents/paths";
import { useToast } from "@src/appV2/lib";
import { APP_V2_USER_EVENTS, logEvent } from "@src/appV2/lib/analytics";
import {
  BookabilityDecision,
  BookabilityUnmetCriteria,
} from "@src/appV2/OpenShifts/ShiftAction/types";
import { useDefinedWorker } from "@src/appV2/Worker/useDefinedWorker";
import { useHistory, useRouteMatch } from "react-router-dom";

import { type ShiftBlockWithMappedDetails } from "../api/useFetchPaginatedShiftBlocks";
import {
  type PreferredWorkerReason,
  useFetchWorkerPreferredStatus,
} from "../api/useFetchPreferredWorker";
import { useShiftBlockBookability } from "../api/useShiftBlockBookability";
import { BookShiftBlockErrors, SHIFT_BLOCK_ACTIONS } from "../utils";
import { BookShiftBlockDialog } from "./BookShiftBlockDialog";
import { MissingDocumentsDialog } from "./MissingDocumentsDialog";
import { RequestToBookShiftBlockButton } from "./RequestToBookShiftBlockButton";

export interface BookShiftBlockButtonProps {
  facilityId: string;
  agentId: string;
  shiftBlockId: string;
  shifts: ShiftBlockWithMappedDetails["shifts"];
  blockAgentRequirement: ShiftBlockWithMappedDetails["agentReq"];
  instantBookPreferredReasons: PreferredWorkerReason[];
  onBookingShiftBlock: () => void;
}

export function BookShiftBlockButton(props: BookShiftBlockButtonProps) {
  const {
    facilityId,
    agentId,
    shiftBlockId,
    shifts,
    blockAgentRequirement,
    instantBookPreferredReasons,
    onBookingShiftBlock,
  } = props;
  const { showErrorToast, showWarningToast } = useToast();
  const worker = useDefinedWorker();

  const workerPreferenceQualification = worker?.preference?.qualification;
  const bookShiftBlockState = useModalState();
  const missingDocumentsDialogState = useModalState();
  const history = useHistory();
  const { path: currentRouterUrl } = useRouteMatch();
  const {
    data: shiftBookabilityData,
    isSuccess: isShiftBookabilitySuccess,
    isLoading: isShiftBookabilityLoading,
  } = useShiftBlockBookability(
    {
      facilityId,
      agentId,
      shiftIds: shifts.map((shift) => shift.id),
      returnFirstUnmetCriteria: false,
    },
    {
      enabled: shifts.length > 0,
    }
  );

  const {
    mutateAsync: getMissingRequirementsForDate,
    data: missingRequirementsForDate,
    isLoading: isGetMissingRequirementsLoading,
    isSuccess: isGetMissingRequirementsSuccess,
  } = useGetMissingRequirementsForDate();

  const shiftWithBlockedStatus = shiftBookabilityData?.data?.find(
    (item) => item.attributes.bookability.decision === BookabilityDecision.BLOCKED
  );

  const { data: workerPreferredStatus, isInitialLoading: isWorkerPreferredStatusLoading } =
    useFetchWorkerPreferredStatus(
      {
        workplaceId: facilityId,
        workerId: agentId,
      },
      {
        enabled: isShiftBookabilitySuccess && !isDefined(shiftWithBlockedStatus),
      }
    );

  if (isShiftBookabilityLoading || isWorkerPreferredStatusLoading) {
    return null;
  }

  const missingDocuments = isGetMissingRequirementsSuccess
    ? missingRequirementsForDate.data.hcpMissingRequirementsForDate.map((params) => ({
        reqId: params.reqId,
        name: params.name,
        visibleToHCP: params.visibleToHCP,
      }))
    : [];

  const missingDocumentsSet = new Set(missingDocuments);

  if (
    isDefined(shiftWithBlockedStatus) &&
    shiftWithBlockedStatus.attributes.bookability.decision === BookabilityDecision.BLOCKED &&
    shiftWithBlockedStatus.attributes.bookability.unmetCriteria.includes(
      BookabilityUnmetCriteria.WORKER_MISSING_REQUIREMENTS
    )
  ) {
    // eslint-disable-next-line unicorn/prefer-at
    const lastShift = shifts[shifts.length - 1];
    return (
      <>
        <LoadingButton
          fullWidth
          isLoading={isGetMissingRequirementsLoading}
          variant="contained"
          size="medium"
          onClick={async () => {
            try {
              if (isDefined(lastShift)) {
                const missingDocumentsForDate = await getMissingRequirementsForDate({
                  hcfId: facilityId,
                  date: lastShift.end,
                  qualification: getQualificationForDocumentCheck({
                    shiftQualificationRequirement: blockAgentRequirement ?? "",
                    selectedWorkerQualification: workerPreferenceQualification ?? "",
                  }),
                });
                if (missingDocumentsForDate?.data.hcpMissingRequirementsForDate.length > 0) {
                  missingDocumentsDialogState.openModal();
                } else {
                  showWarningToast("You have no missing documents for this block.");
                }

                logEvent(APP_V2_USER_EVENTS.SHIFT_BLOCKS, {
                  action: SHIFT_BLOCK_ACTIONS.UPLOAD_REQUIRED_DOCUMENTS_TAPPED,
                  shiftBlockId,
                  facilityId,
                  location: currentRouterUrl,
                  missingDocuments: missingDocumentsForDate,
                });
              }
            } catch (error) {
              const { message: errorMessage = "Unknown error" } =
                error instanceof Error ? error : {};
              logEvent(APP_V2_USER_EVENTS.SHIFT_BLOCKS, {
                action: SHIFT_BLOCK_ACTIONS.UPLOAD_REQUIRED_DOCUMENTS_TAPPED,
                shiftBlockId,
                facilityId,
                location: currentRouterUrl,
                errorMessage,
              });
              showErrorToast(
                "Something went wrong while checking your documents. Please try again."
              );
            }
          }}
        >
          Upload Required Documents
        </LoadingButton>
        <MissingDocumentsDialog
          modalState={missingDocumentsDialogState}
          missingDocuments={missingDocumentsSet}
          analyticsProps={{
            shiftBlockId,
            facilityId,
            location: currentRouterUrl,
          }}
          onConfirm={() => {
            history.push(ACCOUNTS_DOCUMENTS_FULL_PATH);
          }}
        />
      </>
    );
  }

  const isWorkerEligibleForInstantBooking =
    instantBookPreferredReasons.length === 0 ||
    (workerPreferredStatus?.preferred ?? []).some((preferredWorker) =>
      instantBookPreferredReasons.includes(preferredWorker.reason)
    );
  if (isWorkerEligibleForInstantBooking) {
    return (
      <>
        <Button
          fullWidth
          variant="contained"
          color="primary"
          size="medium"
          disabled={!isShiftBookabilitySuccess}
          onClick={() => {
            if (
              isDefined(shiftWithBlockedStatus) &&
              shiftWithBlockedStatus.attributes.bookability.decision === BookabilityDecision.BLOCKED
            ) {
              logEvent(APP_V2_USER_EVENTS.SHIFT_BLOCKS, {
                action: SHIFT_BLOCK_ACTIONS.BOOK_BLOCK_TAPPED,
                shiftBlockId,
                facilityId,
                unmetCriteria: shiftWithBlockedStatus.attributes.bookability.unmetCriteria,
                location: currentRouterUrl,
              });
              // There can be multiple reasons for the shift block booking being blocked, however for the purposes of showing the error message to the user, we are only showing the first unmet criteria.
              showErrorToast(
                BookShiftBlockErrors[
                  shiftWithBlockedStatus.attributes.bookability.unmetCriteria[0]
                ] ?? "Error: You cannot book this block."
              );
            } else {
              logEvent(APP_V2_USER_EVENTS.SHIFT_BLOCKS, {
                action: SHIFT_BLOCK_ACTIONS.BOOK_BLOCK_TAPPED,
                shiftBlockId,
                facilityId,
                location: currentRouterUrl,
              });
              bookShiftBlockState.openModal();
            }
          }}
        >
          Book This Block
        </Button>

        <BookShiftBlockDialog
          modalState={bookShiftBlockState}
          shifts={shifts}
          shiftBlockId={shiftBlockId}
          agentId={agentId}
          facilityId={facilityId}
          onConfirmBooking={onBookingShiftBlock}
        />
      </>
    );
  }

  return (
    <RequestToBookShiftBlockButton
      disabled={!isShiftBookabilitySuccess}
      shiftWithBlockedStatus={shiftWithBlockedStatus}
      facilityId={facilityId}
      agentId={agentId}
      shiftBlockId={shiftBlockId}
      shifts={shifts}
    />
  );
}
