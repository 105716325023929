import { Text } from "@clipboard-health/ui-react";
import {
  Box,
  Button,
  Checkbox,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
} from "@mui/material";
import { type AgentPreferences } from "@src/appV2/Agents/api/useWorkerPreferences/types";
import { useState } from "react";
import { useForm } from "react-hook-form";

import { useEnableDistanceFiltering } from "../hooks/useEnableDistanceFiltering";
import { ShiftBlockDistanceField } from "./DistanceField";

interface FormProps {
  onSubmit: (values: AgentPreferences) => void;
  defaultValues: AgentPreferences;
  closeModal: () => void;
}

export function DistanceDialogForm(props: FormProps) {
  const { onSubmit, defaultValues, closeModal } = props;

  const { enableDistanceFiltering, setEnableDistanceFiltering } = useEnableDistanceFiltering();

  const [localEnableDistanceFiltering, setLocalEnableDistanceFiltering] =
    useState(enableDistanceFiltering);

  const formMethods = useForm<AgentPreferences>({
    defaultValues,
  });
  const { control, handleSubmit, formState } = formMethods;

  return (
    <Box
      component="form"
      sx={{ display: "flex", flexDirection: "column" }}
      onSubmit={handleSubmit((formData) => {
        onSubmit(formData);
        setEnableDistanceFiltering(localEnableDistanceFiltering);
        closeModal();
      })}
    >
      <DialogTitle>Update your distance preference</DialogTitle>

      <DialogContent dividers>
        <Stack
          direction="column"
          alignItems="center"
          justifyContent="center"
          spacing={2}
          marginBottom={2}
          paddingX={{
            xs: 1,
            sm: 2,
            md: 4,
          }}
        >
          <Text bold textAlign="center">
            How far are you willing to travel for work?
          </Text>
          <ShiftBlockDistanceField
            name="distance"
            disabled={!localEnableDistanceFiltering}
            control={control}
          />
        </Stack>
        <Stack
          direction="row"
          justifyContent="center"
          sx={{ alignItems: "center", justifyContent: "flex-start" }}
        >
          <Checkbox
            size="small"
            checked={!localEnableDistanceFiltering}
            onChange={(event) => {
              setLocalEnableDistanceFiltering(!event.target.checked);
            }}
          />
          <Text variant="body2">Show me shift blocks at any distance</Text>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button
          fullWidth
          type="submit"
          variant="contained"
          size="large"
          disabled={formState.isSubmitting}
        >
          Apply
        </Button>
      </DialogActions>
    </Box>
  );
}
