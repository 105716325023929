import { Text } from "@clipboard-health/ui-react";
import BusinessIcon from "@mui/icons-material/Business";
import HomeIcon from "@mui/icons-material/Home";
import { Slider, Stack } from "@mui/material";
import { type AgentPreferences } from "@src/appV2/Agents/api/useWorkerPreferences/types";
import { DISTANCE_PREFERENCE_MAX_VALUE_IN_MILES } from "@src/constants";
import pluralize from "pluralize";
import { useController, type UseControllerProps } from "react-hook-form";

type DistanceField = Pick<AgentPreferences, "distance">;

type ShiftBlockDistanceFieldProps<T extends DistanceField> = UseControllerProps<T> & {
  disabled: boolean;
};

export function ShiftBlockDistanceField<T extends DistanceField>(
  props: ShiftBlockDistanceFieldProps<T>
) {
  const { name, control, disabled } = props;
  const { field } = useController<T>({
    name,
    control,
  });

  // TODO add useId from react 18 once upgraded
  return (
    <>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        width="100%"
        color={disabled ? "text.disabled" : "text.primary"}
      >
        <HomeIcon fontSize="medium" />
        <Text bold variant="h2" color={disabled ? "text.disabled" : "text.primary"}>
          {field.value} {pluralize("mile", field.value)}
        </Text>
        <BusinessIcon fontSize="medium" />
      </Stack>
      <Slider
        valueLabelDisplay="auto"
        aria-label="Distance"
        disabled={disabled}
        step={1}
        min={1}
        max={DISTANCE_PREFERENCE_MAX_VALUE_IN_MILES}
        value={field.value}
        data-testid="shift-block-filter-distance-slider"
        onChange={(_: Event, newValue: number | number[]) => {
          field.onChange(newValue as number);
        }}
      />
    </>
  );
}
